.ant-alert {
  margin-bottom: 24px;
}

.ant-layout-sider-children {
  display: flex;
  flex-direction: column;
}

.ant-table-thead .ant-table-cell {
  font-weight: 700;
}

.ant-form-item-label label {
  white-space: normal;
}

.nl-card-dark {
  background-color: #001529;
  border-color: #4a4a4a;
  transition: all 0.2s;
}

.nl-card-dark * {
  color: rgba(255, 255, 255, 0.65) !important;
}

.nl-card-dark .ant-card-actions {
  background-color: #001529;
  border-top: 1px solid #4a4a4a;
}

.nl-card-dark .ant-card-actions > li:not(:last-child) {
  border-right: 1px solid #4a4a4a;
}

.nl-card-dark .ant-switch {
  background-color: rgb(24 144 255);
}

.nl-card-dark .ant-avatar-image {
  background-color: #ccc;
}

.nl-header {
  margin: -30px -30px 20px;
  padding: 20px 30px;
  background-color: white;
  border-left: 2px #1890ff solid;
  border-bottom: 1px #f0f2f5 solid;
  position: sticky;
  top: 0;
  background: white;
  z-index: 1;
}

.nl-box {
  background-color: white;
  padding: 20px;
}

.nl-attributes {
  display: flex;
  margin-bottom: 8px;
}

.nl-attributes .ant-space-item {
  flex: 1 1 auto;
}

.nl-attributes .ant-space-item:first-child,
.nl-attributes .ant-space-item:last-child {
  flex: initial;
}

.ant-table-tbody > tr > td > .ant-table-wrapper:only-child .ant-table {
  margin: 0;
}

.ant-table-footer {
  padding: 0;
}

.nl-import-result .ant-result-subtitle {
  text-align: left;
}

.ant-input-search .ant-input-affix-wrapper,
.ant-input-search .ant-input {
  padding: 4.3px 11px;
}

.nl-order-print .bordered td {
  border: 1px solid black;
}

.nl-order-print {
  display: none;
}

@media print {
  .nl-order-print {
    display: block;
  }
}

.ant-editor .ant-form-item-control-input {
  min-height: 0;
}

.ant-pagination-total-text {
  margin-right: auto;
}

.ant-select-selection-item-content .ant-tag {
  display: block;
}
